import React from "react"


class HandleData extends React.Component{

    static handleDataToPDF = (code, firstData, formula) => {
        return {
            code: code,
            name: this.handleName(this.capitalLetter(firstData.NOME_PACIENTE)),
            qtdy: this.getDosagemOrDosagemEdit(firstData),
            namereg: `Sra(o). ${firstData.NOME_PACIENTE}`,
            reg: `Reg ${firstData.REGISTRO_NRO}`,
            req: `Req ${firstData.FILIAL_ORIGEM} ${firstData.REQUISICAO_NRO} ${firstData.REQUISICAO_SERIE}`,
            componentes: formula,
            posologia: this.getPosologiaOrPosologiaEdit(firstData),
            doctor: firstData.PRESCRITOR_NOME,
            crm:`CRM: ${firstData.PRESCRITOR_CRM_NRO}`,
            man: this.dateToBr(firstData.DATA_PRODUCAO),
            val: this.dateToBr(firstData.DATA_VALIDADE_PRODUCAO),
            farmresp: this.handleFarmResp(firstData),
            matriz: this.handleAddress(firstData.ENDERECO_PARA_ROTULO),
            tituloFormula: typeof firstData.TITULO_FORMULA == 'string' ? this.capitalLetter(firstData.TITULO_FORMULA) : '',
            descrProduto: typeof firstData.DESCR_PRODUTO == 'string' ? this.capitalLetter(firstData.DESCR_PRODUTO) : '',
            etiqueta: typeof firstData.ETIQUETA_ID_ROTULO == 'string' ? firstData.ETIQUETA_ID_ROTULO.toLowerCase() : 'r160',
            segment: typeof firstData.SEGMENTO == 'string' ? firstData.SEGMENTO.toLowerCase() : ''
        }
    }

    static handleFarmResp(firstData){
        let fone = firstData.ENDERECO_PARA_ROTULO.split("FONE:")
        if(fone.length > 1){
            fone = fone[1]
        }

        return `${firstData.FARMACEUTICA_RESP} <br /> CRF: ${this.maskCRF(firstData.FARMACEUTICA_CRF)} / CNPJ: ${this.maskCNPJ(firstData.FILIAL_ESTOQUE_CNPJ)} <br /> Fone: ${fone}`;
    }

    static maskCNPJ(cnpj){
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    }

    static maskCRF(crf){
        return crf.replace(/^(\d{2})(\d{3})/, "$1.$2")
    }

    static handleAddress(address){
        address = address.split("FONE:")
        if(address.length > 1){
            address = address[0]
        }
        if(typeof address == 'string'){
            address = address.slice(0, -3)
        }
        return address
    }

    static dateToBr = (data) => {
        let date = data.split('-')
        return `${date[2]}/${date[1]}/${date[0]}`
    }

    static dateToEn = (data) => {
        let date = data.split('/')
        return `${date[2]}-${date[1]}-${date[0]} 00:00:00`
    }

    static capitalLetter = (str) => {
        if(typeof str == 'string') {
            str = str.toLowerCase()
            str = str.split(" ");
            for (var i = 0, x = str.length; i < x; i++) {
                if(typeof str[i][0] == 'string'){
                    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
                }
            }
            return str.join(" ");
        }
    }


    static handleData = (data) => {
        let keys = Object.keys(data)
        let returnData = keys.map((name) => {
            return data[name]
        })
        return returnData;
    }   

    static handleDataPedidos = (data) => {
        const newData = [];
        for(var i=1; i<=Object.keys(data).length; i++){
            const FCERTA = data[`FCERTA-${i}`]
            const LABELMAKER = data[`LABELMAKER-${i}`]
            const RECNO = data[`RECNO${i}`]
            if(FCERTA === undefined && LABELMAKER === undefined && RECNO === undefined) continue;    
            newData.push({
                fcerta: FCERTA,
                labelmaker: LABELMAKER,
                recno: RECNO,
            })
        }
        return newData
    }

    static getFormulaOrFormulaEdit = (data, firstData) => {
        var formula = data.map((item, index) => {
            return `${item.DESCR_PRODUTO} ${item.QTDE_PRODUTO}${item.UNIDADE_MEDIDA_PRODUTO}; `
        }).join('')

        if(firstData.EDICAO_FORMULA !== '' && firstData.EDICAO_FORMULA !== undefined){
            formula = firstData.EDICAO_FORMULA
        }

        if(typeof formula === 'string'){
            formula = formula.toUpperCase()
        }

        return HandleData.decodeUtf8(formula);
    }


    static getPosologiaOrPosologiaEdit = (firstData) => {
        let posologia = (firstData.EDICAO_POSOLOGIA === "" || firstData.EDICAO_POSOLOGIA === undefined) ? firstData.POSOLOGIA : firstData.EDICAO_POSOLOGIA
        posologia = posologia.toUpperCase()
        return HandleData.decodeUtf8(posologia)
    }

    static getDosagemOrDosagemEdit = (firstData) => {
        let dosagem = (firstData.EDICAO_DOSAGEM === "" || firstData.EDICAO_DOSAGEM === undefined || typeof firstData.EDICAO_DOSAGEM === 'object') ? `${parseInt(firstData.TOTAL_DE_CAPSULAS)} ${firstData.UNIDADE_DE_VOLUME}` : firstData.EDICAO_DOSAGEM
        return HandleData.decodeUtf8(dosagem)
    }

    static dataIsValid = (data) => {
        return data.RECORDSET === undefined
    }

    static encodeUtf8 = (s) =>  {
        try{
            return unescape( encodeURIComponent( s ) );
        }catch{
            return s
        }
    }
    
    static decodeUtf8 = (s) => {
        try{
            return decodeURIComponent( escape( s ) );
        }catch{
            return s
        }
    }

    static handleName(name){
        if(name.includes(" ")){
            name = name.split(" ")
            if(name.length > 1){
                name = `${name[0]} ${name[name.length-1]}`    
            }
        }
        return name;
    }

    static handleFirstName(obj){
        if(typeof obj.NOME_PACIENTE != 'undefined' && obj.NOME_PACIENTE.length > 0){
            const firstName = obj.NOME_PACIENTE.split(" ")[0]
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()
        }
        return ""
    }
}

export default HandleData;