import React from "react";
import icon_pedidos_composicao_laudos from '../../images/icon_pedidos_composicao_laudos.svg';
import procedencia from '../../images/procedencia.png'
import HandleData from "../../app/handle_data";
import Api  from "../../app/api";


class Laudo extends React.Component {

	state = {
        data: null,
        firstData: null,
        formula: null,
        posologia: null,
		dosagem: null,
        arrayFormula: null,
        handleData: null
    }

    constructor(props){
        super(props)
        this.code = this.props.match.params.code
        this.item = this.props.match.params.item
    }

    async componentDidMount() {
        const reponse = await Api.getBusca(this.code)


        let handleData = HandleData.handleData(reponse)
        this.setState({
            data: handleData[this.item],
        })
    }
    
    render = () => {
		return(<>
			<div className="posologia">
				<div className="top_block nobg">
					<div className="wrap">
						<div className="top">
							<div className="left">
								<div className="title">Composição e Procedência</div>
								{/* <div className="desc">{this.state.data != null ? this.state.data.DESCR_PRODUTO : ''}</div> */}
							</div>
							<div className="right">
								{/* <div className="icon"><img src={icon_pedidos_composicao_laudos} alt="" /></div> */}
							</div>
						</div>
					</div>
				</div>
				{this.state.data == null ? (<div className="loader"></div>) : this.handleContent()}
			</div>
		</>)
	}
	
	handleContent = () => {
		return(
			<div className="content">
				<div className="wrap">
                    <div className="line image-procedencia responsive">
                        <img src={procedencia} />
                    </div>
					{this.state.data != null ? (
                        <>
                            <div className="line">
                                <strong>NF de compra</strong>
                                <p>{this.state.data.LOTE_NRO_NF_COMPRA}</p>
                            </div>
                            <div className="line">
                                <strong>Data de entrada</strong>
                                <p>{this.state.data.LOTE_DT_ENTRADA}</p>
                            </div>
                            <div className="line">
                                <strong>Data de fabricação</strong>
                                <p>{this.state.data.LOTE_DT_FABRICACAO}</p>
                            </div>
                            <div className="line">
                                <strong>Origem</strong>
                                <p>{this.state.data.LOTE_ORIGEM}</p>
                            </div>
                            <div className="line">
                                <strong>Fabricante e/ou distribuidor</strong>
                                <p className="nroControler">{this.state.data.LOTE_FORNECEDOR}</p>
                            </div>
                            <div className="line">
                                <strong>Número do lote</strong>
                                <p>{this.state.data.LOTE_NRO}</p>
                            </div>
                            <div className="line">
                                <strong>Número de controle</strong>
                                <p>{this.state.data.LOTE_NRO_CONTROLE}</p>
                            </div>
                            {/* <div className="line">
                                <strong>Densidade</strong>
                                <p>{this.state.data.LOTE_DENSIDADE}</p>
                            </div>
                            <div className="line">
                                <strong>Fator de correção</strong>
                                <p>{this.state.data.LOTE_FATOR_CORRECAO}</p>
                            </div>
                            <div className="line">
                                <strong>Teor porcentual</strong>
                                <p>{this.state.data.LOTE_TEOR_PERCENTUAL}</p>
                            </div> */}
                        </>
					) : null}
					
				</div>
			</div>
		)
	}
    
}


export default Laudo;