import React from "react";
import icon_pedidos_composicao_laudos from '../../images/icon_pedidos_composicao_laudos.svg';
import click_no_componenete from '../../images/click_no_componenete.png';
import HandleData from "../../app/handle_data";
import Api  from "../../app/api";


class Posologia extends React.Component {

	state = {
        firstData: null,
        formula: null,
        data: null,
        posologia: null,
		dosagem: null,
		arrayFormula: null
    }

    constructor(props){
        super(props)
        this.code = this.props.match.params.code
    }

    async componentDidMount() {
		const reponse = await Api.getBusca(this.code)
		let handleData = HandleData.handleData(reponse)
		let firstData = handleData[0]
		let formula = HandleData.getFormulaOrFormulaEdit(handleData, firstData)
		let arrayFormula = formula.trim().split(';')
		
		let data = HandleData.handleDataToPDF(this.code, firstData, formula)
		this.setState({
			firstData: firstData,
			data: data,
			formula: formula,
			posologia: HandleData.getPosologiaOrPosologiaEdit(firstData),
			dosagem: HandleData.getDosagemOrDosagemEdit(firstData),
			arrayFormula: arrayFormula
		})
	}
	
	handleContent = () => {
		return(
			<div className="content">
				<div className="wrap">
					{this.state.data !== null ? (
						this.state.arrayFormula.map((item, index) => {
							if(item !== ''){
								return <a href={`/laudo/${this.code}/${index}`} key={index} className="box">{item}</a>
							}	
						})
					) : null}
					
				</div>
			</div>
		)
	}


	render = () => {
		console.log(this.state)
		return(<>

			<div className="posologia">
				<div className="top_block nobg">
					<div className="wrap">
						<div className="top">
							<div className="left">
								<div className="title">Composição e Procedência</div>
								<img src={click_no_componenete} className="detail-composicao" />
							</div>
							
						</div>
					</div>
				</div>
				{this.state.data == null ? (<div className="loader"></div>) : this.handleContent()}
			</div>
		</>)
	}
}


export default Posologia;