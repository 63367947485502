import React, {useEffect, useState} from "react";
import icon_pedidos_acompanhamento from '../../images/icon_pedidos_acompanhamento.svg';
import icon_button_add from '../../images/icon_button_add.svg'
// import icon_tip from '../../images/icon_tip.svg';
import  Api  from "../../app/api";
import Authentication  from "../../app/authentication";
// import HandleData from "../../app/handle_data";
import Moment from 'moment';

const Acompanhamento = (props) => {

    const [data, setData] = useState([])
    const code = props.match.params.code

    useEffect(() => {
        async function getRecords() {
            const reponse = await Api.getRecords(code)
            setData(handleDataByDay(reponse))
        }
        getRecords()
    }, [])

    const handleDataByDay = (reponse) => {
        var dataByDay = new Map()
        for(const item of reponse.object){
            let day = Moment(item.created_at).format('DD-MM-YYYY')
            if(typeof dataByDay[day] == 'undefined'){ dataByDay[day] = [] }
            dataByDay[day].push(item)
        }
        return dataByDay
    }

    return (
        <>
            <div className="acompanhamento">
                <div className="top_block nobg">
                    <div className="wrap">
                        <div className="top">
                            <div className="left">
                                <div className="title">Acompanhamento</div>
                                <div className="description">Toque no registro para adicionar um comentário.</div>
                            </div>
                            <div className="right">
                                <div className="icon"><img src={icon_pedidos_acompanhamento} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="wrap">
                        {/* <div className="tip"><img src={icon_tip} alt="" /><span>Toque no registro para adicionar um comentário.</span></div> */}
                        <div className="timeline">
                            <div className="line"></div>
                            {Object.keys(data).map((day, index) => {
                                return(
                                    <div className="day" key={index}>
                                        <div className="data">
                                            <div className="dot"></div>
                                            <span>{day.replaceAll('-', '/')} - {Object.keys(data).length - index}º dia</span>
                                        </div>
                                        {data[day].map((item, index) =>{
                                            return(
                                                // <a href={`/comment/${code}/${item.id}/false/${day}`} className="item" key={index}>
                                                <a href='#' className="item" key={index}>
                                                    <div className="dot"></div>
                                                    <div className="box">
                                                        <div className="hour">às {Moment(item.created_at).format('HH:mm')}</div>
                                                        <div className="text">Ingeriu o medicamento <br />{item.comment != '' ? `Comentário: ${item.comment}`  : ''} </div>
                                                        <div style={{position: 'absolute', right: '10px', fontSize: '20px', color: '#666'}}>+</div>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                    </div>     
                                )
                            })}
                            <a href={'/comment/' + code + '/false/false'} className="button float"><img src={icon_button_add} alt="" /></a>                    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Acompanhamento;