
const Authentication = {
    authenticationSuccess: (api, fCerta) => {
        Authentication.setSessionAuthentication()
        Authentication.setSessionAuthenticationData({
			api: api,
			fCerta: fCerta
		})
        window.location.href = '/home'
    },
    setSessionAuthenticationData : (data) => {
        localStorage.setItem("dataUser",  JSON.stringify(data));
    },
    getSessionAuthenticationData(){
        return JSON.parse(localStorage.getItem("dataUser"))
    },
    setSessionAuthentication : () => {
        localStorage.setItem("isAuthenticated", true);
    },
    removeSessionAuthentication : () => {
        localStorage.setItem("isAuthenticated", false);
    },
    getSessionAuthentication : () => {
        return localStorage.getItem("isAuthenticated")
    },
}

export default Authentication