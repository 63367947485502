import React from "react";
import icon_pedidos_posologia from '../../images/icon_pedidos_posologia.svg';
import posologia from '../../images/posologia.png';
import HandleData from "../../app/handle_data";
import Api  from "../../app/api";


class Posologia extends React.Component {

	state = {
        firstData: null,
        formula: null,
        data: null,
        posologia: null,
        dosagem: null,
    }

    constructor(props){
        super(props)
        this.code = this.props.match.params.code
    }

    async componentDidMount() {
		const dataResponse = await Api.getBusca(this.code)
		let handleData = HandleData.handleData(dataResponse)
		let firstData = handleData[0]
		let formula = HandleData.getFormulaOrFormulaEdit(handleData, firstData)
		let data = HandleData.handleDataToPDF(this.code, firstData, formula)
		this.setState({
			firstData: firstData,
			data: data,
			formula: formula,
			posologia: HandleData.getPosologiaOrPosologiaEdit(firstData),
			dosagem: HandleData.getDosagemOrDosagemEdit(firstData)
		})
	}
	
	handleContent = () => {
		return(
			<div className="content">
				<div className="wrap">
					<div className="box">
						{this.state.data != null ? this.state.data.posologia : ''}
					</div>
				</div>
			</div>
		)
	}


	render = () => {
		console.log(this.state)
		return(<>

			<div className="posologia">
				<div className="top_block nobg">
					<div className="wrap">
						<div className="top">
							<div className="left">
								<div className="title">Posologia</div>
								<div className="desc">
									<img src={posologia} className="detail-composicao" />
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.data == null ? (<div className="loader"></div>) : this.handleContent()}
			</div>
		</>)
	}
}


export default Posologia;