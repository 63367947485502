import React from "react";

const Comentario = () => (
	<>
        <div className="comentario">
            <div className="top_block nobg">
                <div className="wrap">
                    <div className="top">
                        <div className="left">
                            <div className="title">Registro</div>
                            <div className="desc">às 15h17</div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="status">Ingeriu o medicamento</div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="wrap">
                    <div className="descricao">
                        <h3>Comentário</h3>
                        <div className="desc">Utilize o espaço abaixo para reportar suas observações, como reações e melhorias.</div>
                    </div>
                    <div className="comentarios">
                        <div className="item">
                            <div className="box">Após 1h tive um enjoo estranho. Não sei se foi pelo medicamento ou algo que comi.</div>
                            <div className="data">às 20h05</div>
                        </div>
                    </div>
                    <textarea placeholder="Digite seu comentário..."></textarea>
                    <a href="/home" className="button outline">Comentar</a>
                    <a href="/home" className="button gray" style={{marginTop:"15px"}}>Registrar sem comentário</a>
                    <a href="/home" className="button float bottom">Eu não tomei. Desfazer.</a>
                </div>
            </div>
        </div>
	</>
);

export default Comentario;