import React from "react";
import almacompanha from '../../images/alm_acompanha.png';


class DoLogin extends React.Component {
    constructor(props){
        super(props)
    }

    async componentDidMount() {
        
    }
    
    render = () => {
		return(<>
			<div className="posologia">
				<a className="top_block nobg dologin" href="/login">
					<img src={almacompanha} />
				</a>
			</div>
		</>)
	}
	
	handleContent = () => {
        console.log(this.state.data)
		return(
			<div className="content">
				<div className="wrap">
					{this.state.data != null ? (
                        <>
                            <div className="line">
                                <strong>NF de compra</strong>
                                <p>{this.state.data.LOTE_NRO_NF_COMPRA}</p>
                            </div>
                            <div className="line">
                                <strong>Data de entrada</strong>
                                <p>{this.state.data.LOTE_DT_ENTRADA}</p>
                            </div>
                            <div className="line">
                                <strong>Data de fabricação</strong>
                                <p>{this.state.data.LOTE_DT_FABRICACAO}</p>
                            </div>
                            <div className="line">
                                <strong>Origem</strong>
                                <p>{this.state.data.LOTE_ORIGEM}</p>
                            </div>
                            <div className="line">
                                <strong>Número do lote</strong>
                                <p>{this.state.data.LOTE_NRO}</p>
                            </div>
                            <div className="line">
                                <strong>Número de controle</strong>
                                <p>{this.state.data.LOTE_NRO_CONTROLE}</p>
                            </div>
                            {/* <div className="line">
                                <strong>Densidade</strong>
                                <p>{this.state.data.LOTE_DENSIDADE}</p>
                            </div>
                            <div className="line">
                                <strong>Fator de correção</strong>
                                <p>{this.state.data.LOTE_FATOR_CORRECAO}</p>
                            </div>
                            <div className="line">
                                <strong>Teor porcentual</strong>
                                <p>{this.state.data.LOTE_TEOR_PERCENTUAL}</p>
                            </div> */}
                        </>
					) : null}
					
				</div>
			</div>
		)
	}
    
}


export default DoLogin;