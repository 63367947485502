import React, {useState} from "react";
import icon from '../../images/icon_login.svg';
import InputMask from 'react-input-mask';
import Api  from "../../app/api";
import Authentication  from "../../app/authentication";


const Singup = (props) => {

	const [label, setLabel] = useState('Verificar');
	//const [cpf, setCpf] = useState('179.992.918-38');
	const [cpf, setCpf] = useState();
	const [disabled, setDisabled] = useState(false);
	const validarCpf = require('validar-cpf');
	const [showPassword, setShowPassword] = useState(false)
	const [password, setPassword] = useState('6949519')
	const [confirmPassword, setConfirmPassword] = useState('6949519')
	const [email, setEmail] = useState('itamar.developer@gmail.com')
	const [fCerta, setFCerta] = useState(null)

	const submitCheckCpf = async () => {
		const cpfClean = clearCpf(cpf)
		if(!validarCpf(cpfClean)) {
			alert("Por favor, digite um CPF válido");
			return
		}		
		
		setLabel("Aguarde...")
		setDisabled(true)

		const response = await Api.getUserByCpf(cpfClean)
        handleResponse(response)
		setDisabled(false)
		setLabel("Verificar")
    }

	const submitSingup = async () =>{
		const cpfClean = clearCpf(cpf)

		if(password != confirmPassword){
			alert("As senhas não conferem.")
			return
		}

		console.log(fCerta)
		const response = await Api.saveCustomer({
			name: fCerta.CLIENTE_NOME,
			cpf: cpfClean,
			email: email,
			password: password,
			telephone: "11 994934175",
		})

		if(!response.status){
			var messagem = 'Por favor, verifique os seguintes erros \n\n'
			response.object.forEach((item) => {
				messagem += `${item.message}\n`
			})
			return alert(messagem)
		}


		Authentication.authenticationSuccess(response.object, fCerta)
	}

	const handleResponse = (response) => {
		if(response.RETORNO === 'ERRO'){
			return alert(response.DESCRICAO)
		}
		if(response.RETORNO === "OK"){
			setFCerta(response)
			setShowPassword(true)
		}
	}

	const onChangeCpf = (evt, value) => {
		setCpf(evt.target.value)
	}

	const onChangePassword = (evt, value) => {
		setPassword(evt.target.value)
	}

	const onChangeConfirmPassword = (evt, value) => {
		setConfirmPassword(evt.target.value)
	}

	const onChangeEmail = (evt, value) => {
		setEmail(evt.target.value)
	}

	const clearCpf = () => {
		return cpf.replace(/\D/gim, '');
	}

	return (
		<>
			<div className="top_block">
				<div className="wrap">
					<div className="top">
						<div className="title">Olá, informe seu C.P.F</div>
						<div className="icon"><img src={icon} alt=""/></div>
					</div>
					<div className="bottom">
						<div className="desc">Utilize seu CPF para acessar sua conta e fazer novo registo.</div>
					</div>
				</div>
			</div>

			<div className={!showPassword ? 'content animation-show' : 'content animation-hide'}>
				<div className="wrap">
					<div className="input_log paciente">
						<InputMask autoFocus mask="999.999.999-99" val="" className="single" name="cpf" placeholder="CPF"  onChange={onChangeCpf} defaultValue={cpf} />
					</div>
					<button href="#" disabled={disabled} onClick={submitCheckCpf}  className="button">{label}</button>
				</div>
			</div>	

			<div className={showPassword ? 'content animation-show' : 'content animation-hide'}>	
				<div className="wrap">
					<div className="input_log paciente">
						<label>E-mail</label>
						<InputMask autoFocus val="" className="single"  name="email" onChange={onChangeEmail} type="text" />

						<label>Digite uma nova senha</label>
						<InputMask autoFocus val="" className="single"  name="password" onChange={onChangePassword} type="password" />

						<label>Digite-a novamente</label>
						<InputMask autoFocus val=""  className="single" name="confirmPassword" onChange={onChangeConfirmPassword} type="password" />
					</div>
					<button href="#" disabled={disabled} onClick={submitSingup}  className="button">Cadastrar</button>
				</div>
			</div>	
		</>
	)
};

export default Singup;