import React, { useState } from "react";
import icon_popup_qrcode from '../../images/icon_popup_qrcode.svg';
import icon_popup_registro_add from '../../images/icon_popup_registro_add.svg';
import icon_popup_comentario_add from '../../images/icon_popup_comentario_add.svg';
import icon_popup_registo_removido from '../../images/icon_popup_registo_removido.svg';

const Popup = (props) =>{

    const [show, setShow] = useState(true)
    
    return(            
        <div className={show ? 'popup' : 'popup animation-hide'}>
            <div className="wrap">
                <div className="text">
                    <img src={icon_popup_qrcode} alt="" />
                    <img src={icon_popup_registro_add} alt="" style={{display:"none"}} />
                    <img src={icon_popup_comentario_add} alt="" style={{display:"none"}} />
                    <img src={icon_popup_registo_removido} alt="" style={{display:"none"}}/>
                    <h3>Olá, {props.userName == '' ? '...' : props.userName}</h3>
                    <div className="desc">Você acabou de administrar o nutracêutico {props.code}?</div>
                    
                </div>
                <div className="buttons">
                    <a onClick={() => { setShow(false) }} className="option">Sim</a>
                </div>
                <div className="buttons">
                    <a onClick={() => { window.location.href = '/pedido/' + props.code  }} className="option">NÃO, IR PARA O MENU</a>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    )
}

export default Popup;