import './Sass.scss';
import Header from './components/header/header'
import Main from "./main";
import Popup from './components/popup/popup';


function App() {
	return (
		<>
			<Header />
			<Main />
		  
		</>
	);
}

export default App;
