import React, {useEffect, useState} from "react";
import icon_posologia from '../../images/icon_posologia.svg';
import icon_laudo from '../../images/icon_laudo.svg';
import icon_arrow from '../../images/icon_arrow_right.svg';
import Authentication  from "../../app/authentication";
import Api  from "../../app/api";
import HandleData from "../../app/handle_data"

const Home = () => {

	const [authentication] = useState(Authentication.getSessionAuthenticationData())
	const [data, setData] = useState([])

	useEffect(() => {
		async function getPedidos() {
			const reponse = await Api.getPedidos(authentication.fCerta)
			setData(HandleData.handleDataPedidos(reponse))
		}
		if(Authentication.getSessionAuthentication()) {
			getPedidos()	
		} else {
			window.location.href = '/login'
		}
	}, [])

	return (
		<>
			<div className="top_block">
				<div className="wrap">
					<div className="top">
						<div className="title">Olá, {authentication != null ? authentication.fCerta.CLIENTE_NOME : ''}</div>
					</div>
					<div className="bottom">
						<div className="desc">Não se esqueça de registrar via QR code a cada vez que administrar uma fórmula.</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="wrap">
					<h2>Fórmulas que estou administrando</h2>

					{data.length === 0 ? (<div className="loader">Loading...</div>) : ''}
					<div className="list_pedido">
						{data.map((item, index) => {
							const {recno} = item
							const {fcerta} = item
							const {labelmaker} = item
							
							var data = null
							if(fcerta === 'VAZIO') 
								data = labelmaker
							if(labelmaker === 'VAZIO')
								data = fcerta

							return(
								<a className="item" key={index} href={`pedido/${recno.REQ_FILIAL}-${recno.REQ_NRO}-${recno.REQ_SERIE}`}>
									<div className="top">
										<div className="left">
											<div className="pedido">Pedido {recno.REQ_FILIAL}-{recno.REQ_NRO}-{recno.REQ_SERIE}</div>
											<div className="categ">{``}</div>
										</div>
										<div className="right">
											<div className="arrow">
												<img src={icon_arrow} alt=""/>
											</div>
										</div>
									</div>
									<div className="bottom">
										<div className="left">
											<div className="posologia">
												<div className="icon"><img src={icon_posologia} alt="" /></div>
												<span>{data === 'VAZIO' ? 'Sem informacão' : data.TEXTO_POSOLOGIA}</span>
											</div>
											<div className="laudo">
												<div className="icon"><img src={icon_laudo} alt="" /></div>
												<span>{data === 'VAZIO' ? 'Sem informacão' : data.TEXTO_FORMULA}</span>
											</div>
										</div>
										<div className="right">
											{ data.MEDICAMENTO_TIPO == '1' &&  <div className="cat medicamentos"></div> }
											{ data.MEDICAMENTO_TIPO == '2' &&  <div className="cat nutraceutico"></div> }
											{ data.MEDICAMENTO_TIPO == '*' &&  <div className="cat nutraceutico"></div> }
											{ data.MEDICAMENTO_TIPO == '3' &&  <div className="cat dermocosmeticos"></div> }
										</div>
									</div>
								</a>
							)
						})}
						
						
					</div>
				</div>
			</div>
		</>
	)
};

export default Home;