import md5 from "react-native-md5"
import Authentication  from "../app/authentication";

class Api {
    static getURL = () => {
        if(window.location.host.indexOf('hml') === 0 || window.location.host.indexOf('localhost') === 0 ){
            return 'https://almofariz.websiteseguro.com/ws.fcerta/wBusca2.php'
        }
        return 'https://almofariz.websiteseguro.com/ws.fcerta/wBusca.php'
    }

    static getUrlFarmaciaApi(){
        return 'https://api.farmaciapersonalizada.com.br/'
        //return "http://localhost:8181/"
    }

    static optionXML(){
        var he = require('he');
        return {
            attributeNamePrefix : "@_",
            attrNodeName: "attr", //default is 'false'
            textNodeName : "#text",
            ignoreAttributes : false,
            ignoreNameSpace : false,
            allowBooleanAttributes : true,
            parseNodeValue : false,
            parseAttributeValue : true,
            trimValues: true,
            cdataTagName: "__cdata", //default is 'false'
            cdataPositionChar: "\\c",
            parseTrueNumberOnly: false,
            arrayMode: false, //"strict"
            attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
            tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
            stopNodes: ["parse-me-as-string"]
        }
    }

    static getTodayString(){
        const month = (new Date().getMonth()+1) < 10 ? `0${new Date().getMonth()+1}` : new Date().getMonth()+1
        const day = (new Date().getDate()) < 10 ? `0${new Date().getDate()}` : new Date().getDate()
        const year = new Date().getFullYear()
        return  `${year}${month}${day}`
    }

    static getKey() {
        return `${md5.hex_md5("ProjetoRotulo")}@|@${md5.hex_md5('172.67.147.112')}@|@${md5.hex_md5(Api.getTodayString())}`;
    }

    static parseXMLToObject(text){
        let data = new TextDecoder("ISO-8859-1").decode(text);
        
        var parser = require('fast-xml-parser');
        var jsonObj = null
        if( parser.validate(data) === true) {
            jsonObj = parser.parse(data, Api.optionXML());
        }
        var tObj = parser.getTraversalObj(data, Api.optionXML());
        jsonObj = parser.convertToJson(tObj, Api.optionXML());
        return jsonObj;
    }

    static hadleFetch = async (webBusca, items) => {
        let endpoint = Api.getURL()
        let formData = new FormData();
        formData.append('KEY', Api.getKey());
        formData.append('webBUSCA', webBusca);
        Object.keys(items).forEach(function(key) {
            formData.append(key, items[key]);
        });
        const response = await fetch(endpoint, {
            method: "POST",
            body: formData
        })
        const arrayBuffer = await response.arrayBuffer()
        const data = Api.parseXMLToObject(arrayBuffer)
        return data
    }

    static getBusca = async (params) => {
        let paramsArray = params.split('-')
        let filial = paramsArray[0]
        let requiNro = paramsArray[1]
        let requiSerie = paramsArray[2]

        const data = await Api.hadleFetch('REQsD', { 
            FILIAL_O: filial,
            REQUI_NRO: requiNro,
            REQUI_SERIE: requiSerie,
        })
        return data.REQsD
    }

    static saveRecord = async (req, comment) => {
        let endpoint =  `${Api.getUrlFarmaciaApi()}records`
        let data = {req: req, comment: comment}
        const rawResponse = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data)
        });
        return await rawResponse.json();
    }

    

    static saveCustomer = async (customer) => {
        let endpoint = `${Api.getUrlFarmaciaApi()}customers`
        const rawResponse = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(customer)
        });
        return await rawResponse.json();
    }

    static authenticationCustomer = async (login) => {
        let endpoint = `${Api.getUrlFarmaciaApi()}customers/authentication`
        const rawResponse = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(login)
        });
        return await rawResponse.json();
    }

    static getRecords = async (req) => {
        let endpoint =  `${Api.getUrlFarmaciaApi()}records/${req}`
        const rawResponse = await fetch(endpoint);
        return await rawResponse.json()
    }

    static getUserByCpf = async (cpf) => {
        const data = await Api.hadleFetch('LOGINcli', { DOCTO: `CPF: ${cpf}`, })
        return data.LOGINcli
    }

    static getPedidos = async(user) => {
        const data = await Api.hadleFetch('REQsCli', { 
            CLIENTE_COD: user.CLIENTE_COD,
        })
        return data.REQsCli
    }

    static getComment = async (id) => {
        let endpoint =  `${Api.getUrlFarmaciaApi()}records/${id}`
        const rawResponse = await fetch(endpoint);
        return await rawResponse.json();
    }
}

export default Api