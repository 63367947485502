import React from "react";
import { BrowserRouter,Switch, Route } from 'react-router-dom'
import Doctor from './pages/doctor/doctor'
import Login from './pages/login/login'
import Singup from './pages/singup/singup'
import Bemvindo from './pages/login/bemvindo'
import Acessar from './pages/login/acessar'
import Error from './pages/login/error'
import Home from './pages/home/home'
import Pedido from './pages/pedidos/pedido'
import Posologia from './pages/pedidos/posologia'
import Comment from './pages/comment/comment'
import Composicao from './pages/pedidos/composicao'
import Acompanhamento from './pages/pedidos/acompanhamento'
import Comentario from './pages/pedidos/comentario'
import Laudo from './pages/pedidos/laudo'
import DoLogin from './pages/pedidos/dologin'

class Main extends React.Component{ 

  render = () => {
    return (
      <main id="main">
        <BrowserRouter>
            <Switch>
                <Route exact path='/' component={Login}/>
                <Route exact path='/doctor' component={Doctor}/>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/singup' component={Singup}/>
                <Route exact path='/login/bemvindo' component={Bemvindo}/>
                <Route exact path='/login/lostpassword' component={Bemvindo}/>
                <Route exact path='/login/acessar' component={Acessar}/>
                <Route exact path='/login/error' component={Error}/>
                <Route exact path='/home' component={Home}/>
                <Route exact path='/dologin' component={DoLogin}/>
                <Route exact path='/pedido/:code' component={Pedido}/>
                <Route exact path='/posologia/:code' component={Posologia}/>
                <Route exact path='/composicao/:code' component={Composicao}/>
                <Route exact path='/acompanhamento/:code' component={Acompanhamento}/>
                <Route exact path='/laudo/:code' component={Laudo}/>
                <Route exact path='/comentario' component={Comentario}/>
                <Route exact path='/checkin/:code' component={Pedido}/>
                {/* <Route exact path='/checkin/:code' component={Checkin}/> */}
                <Route exact path='/comment/:code' component={Comment}/>
                <Route exact path='/comment/:code/:id' component={Comment}/>
                <Route exact path='/comment/:code/:id/:popup' component={Comment}/>
                <Route exact path='/comment/:code/:id/:popup/:day' component={Comment}/>
                <Route exact path='/laudo/:code/:item' component={Laudo}/>
            </Switch>
        </BrowserRouter>
      </main> 
    )
  }
}

export default Main;