import React from "react";

const Acessar = () => (
	<>
		<div className="top_block">
			<div className="wrap">
				<div className="top">
					<div className="title">Olá, Giovanna</div>
					<div className="persona"><span>GM</span></div>
				</div>
				<div className="bottom">
					<div className="desc">Seu painel de acompanhamento personalizado está te esperando. Vamos?</div>
				</div>
			</div>
		</div>
		<div className="content">
			<div className="wrap">
				<div className="input">
					<label htmlFor="pass">Digite sua senha </label>
					<input type="password" id="pass" name="cpf" placeholder=""/>
				</div>
				<a href="/home" className="button">Entrar</a>
			</div>
		</div>
	</>
);

export default Acessar;