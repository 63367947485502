import React from "react";

const Acessar = () => (
	<>
		<div className="top_block">
			<div className="wrap">
				<div className="top">
					<div className="title">CPF não encontrado</div>
				</div>
				<div className="bottom">
					<div className="desc">Seu CPF não está cadastrado. Entre em contato com nosso suporte técnico.</div>
				</div>
			</div>
		</div>
		<div className="content">
			<div className="wrap">
				<a href="/login/" className="button">Suporte técnico por e-mail</a>
			</div>
		</div>
	</>
);

export default Acessar;