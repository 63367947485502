import React, {useEffect, useState} from "react";
import  Api  from "../../app/api";
// import icon_medico from '../../images/icon_medico.svg';
import icon_pedidos_composicao_laudos from '../../images/icon_pedidos_composicao_laudos.svg';
import icon_pedidos_posologia from '../../images/icon_pedidos_posologia.svg';
import icon_pedidos_acompanhamento from '../../images/icon_pedidos_acompanhamento.svg';
import backgroun_acompanhamento from '../../images/backgroun_acompanhamento.png'
import icon_arrow_right from '../../images/icon_arrow_right.svg';
import HandleData from "../../app/handle_data";
import Authentication  from "../../app/authentication";

const Pedido = (props) => {

    const [data, setData] = useState(null)
	const [firstData, setFirstData] = useState(null)
    const [formula, setFormula] = useState(null)
    const [posologia, setPosologia] = useState(null)
    const [dosagem, setDosagem] = useState(null)
    const [code] = useState(props.match.params.code)

    useEffect(() => {
		async function getPedido() {
			const reponse = await Api.getBusca(code)
            let handleData = HandleData.handleData(reponse)
            let firstData = handleData[0]
            let formula = HandleData.getFormulaOrFormulaEdit(handleData, firstData)
            let data = HandleData.handleDataToPDF(code, firstData, formula)

            setFirstData(firstData)
            setData(data)
            setFormula(formula)
            setPosologia(HandleData.getPosologiaOrPosologiaEdit(firstData))
            setDosagem(HandleData.getDosagemOrDosagemEdit(firstData))
		}
		getPedido()
        ruleAuthentication()
	}, [])

    const ruleAuthentication = () => {
        // if(!Authentication.getSessionAuthentication()){
        //     window.location.href = '/login'
        // }
    }

    const handleContent = () => {
        return (
            <div className="wrap">
                <a className="box laudos" href={`/composicao/${code}`}>
                    <div className="title">
                        <img src={icon_pedidos_composicao_laudos} alt="" />
                        <b>Composição e Procedência</b>
                        <div className="arrow"><img src={icon_arrow_right} alt="" /></div>
                    </div>
                    <div className="desc">
                        {formula}
                    </div>
                    
                </a>
                <a className="box posologia" href={`/posologia/${code}`}>
                    <div className="title">
                        <img src={icon_pedidos_posologia} alt="" />
                        <b>Posologia</b>
                        <div className="arrow"><img src={icon_arrow_right} alt="" /></div>
                    </div>
                    <div className="desc">
                        {posologia}
                    </div>
                </a>
                
                {/* <a className="box acompanhante"  href={!Authentication.getSessionAuthentication() ? `/dologin` : `/acompanhamento/${code}`}>
                    <img className="img-fluid" src={backgroun_acompanhamento} />
                </a> */}
            </div>
        )
    }

    const layout = () => {
        var style = ''
        if(firstData != null && firstData.MEDICAMENTO_TIPO == '1') style = 'medicamentos'
        if(firstData != null && (firstData.MEDICAMENTO_TIPO == '2' || firstData.MEDICAMENTO_TIPO == '*')) style = 'nutraceutico'
        if(firstData != null && firstData.MEDICAMENTO_TIPO == '3') style = 'dermocosmeticos'

        return (
            <>
                <div className="pedido">
                    <div className={`top_block ${style}`}>
                        <div className="wrap">
                            <div className="top">
                                <div className="left" style={{display: 'none'}}>
                                    <div className="data">
                                        <b>Data de entrega</b>
                                        <div>18/09/2020</div>
                                    </div>
                                </div>
                                <div className="left">
                                    <div className="">
                                        <b style={{fontSize: 20, marginTop: 30}}>Olá, {firstData != null ? firstData.NOME_CLIENTE : ''}</b>
                                        {data == null ? '' : (
                                            <div className="val info-formula">
                                                <h3>Informações sobre sua fórmula manipulada:</h3>
                                                <p>Fabricação: {data.man}</p>
                                                <p>Vencimento: {data.val}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="bottom" style={{visibility: 'hidden'}}>
                                <div className="status andamento">Em Andamento</div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        { handleContent() }
                    </div>
                </div>
            </>
        )
    }
    
    return(
        <>{data == null || firstData == null ? (<div className="loader">Loading...</div>) : layout()}</>
    )
    
};

export default Pedido;