import React from "react";
import Api  from "../../app/api";
import icon_popup_qrcode from '../../images/icon_popup_qrcode.svg';
import Authentication  from "../../app/authentication";
import Moment from 'moment';
import Popup from '../../components/popup/popup';
import HandleData from "../../app/handle_data"


class Comment extends React.Component {
    
    state = {
        comment: "",
        userName: "",
    }

    constructor(props){
        super(props)
        this.code = this.props.match.params.code
        this.id = this.props.match.params.id
        this.popup = this.props.match.params.popup
        this.day = this.props.match.params.day


        this.getComment()
        this.getBusca()
    }

    saveRecord = async () => {
        const reponse = await Api.saveRecord(this.code, this.state.comment)
        this.goToAcompanhamento()
    }


    goToAcompanhamento = () => {
        this.props.history.push(`/acompanhamento/${this.code}`);
    }

    goToPedido = () => {
        this.props.history.push(`/pedido/${this.code}`);
    }

    getComment = async () => {
        if(this.id > 0){
            const response = await Api.getComment(this.id)
            this.setState({comment: response.object.comment})
        }
    }

    getBusca = async () => {
        const responseApi = await Api.getBusca(this.code)
        const firstData = HandleData.handleData(responseApi)[0]
        this.setState({userName: HandleData.handleFirstName(firstData)})
    }
	
    render = () => {
        return (
            <>
                {this.popup != 'false' ? <Popup userName={this.state.userName} code={this.code} /> : null}
                <div className="comentario">
                    <div className="top_block nobg">
                        <div className="wrap">
                            <div className="top">
                                <div className="left">
                                    <div className="title">Registro</div>
                                    <div className="desc">às {Moment().format('DD/MM/YYYY HH:mm:ss')}</div>
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="status">Ingeriu o medicamento</div>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="wrap">
                            <div className="descricao">
                                <h3>Comentário</h3>
                                <div className="desc">Utilize o espaço abaixo para reportar suas observações, como reações e melhorias.</div>
                            </div>
                            {/* <div className="comentarios">
                                <div className="item">
                                    <div className="box">Após 1h tive um enjoo estranho. Não sei se foi pelo medicamento ou algo que comi.</div>
                                    <div className="data">às 20h05</div>
                                </div>
                            </div> */}
                            <textarea defaultValue={this.state.comment} onChange={(evt) => this.setState({comment: evt.target.value})}  placeholder="Digite seu comentário..."></textarea>
                            <button onClick={this.saveRecord} className="button outline">Comentar</button>
                            <button onClick={this.saveRecord} className="button gray" style={{marginTop:"15px"}}>Registrar sem comentário</button>
                            <button onClick={this.goToPedido} className="button float bottom">Eu não tomei. Desfazer.</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default Comment;