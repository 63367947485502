import React from "react";
import icon_search from '../../images/icon_search.svg';
import icon_arrow from '../../images/icon_arrow_right.svg';

const Doctor = () => (
  <>
    <div className="medico">
      <div className="top_block">
        <div className="wrap">
          <div className="top">
            <div className="left">
              <div className="title"><span>Olá,</span> Dr. Henrique</div>
              </div>
              <div className="right">
              <div className="icon"><img src="" alt="" /></div>
              </div>
          </div>
          <div className="bottom">
            <div className="search">
              <input type="text" placeholder="Seus pacientes" className="search"></input>
              <button><img src={icon_search} alt="" /></button>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="wrap">
          <div className="list">
            <div className="item">
              <div className="persona"><span>JA</span></div>
              <div className="nome">Juliana Alves</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
            <div className="item">
              <div className="persona"><span>AS</span></div>
              <div className="nome">Ana Sales</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
            <div className="item">
              <div className="persona"><span>RA</span></div>
              <div className="nome">Roberto Amaral</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
            <div className="item">
              <div className="persona"><span>JA</span></div>
              <div className="nome">Juliana Alves</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
            <div className="item">
              <div className="persona"><span>AS</span></div>
              <div className="nome">Ana Sales</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
            <div className="item">
              <div className="persona"><span>RA</span></div>
              <div className="nome">Roberto Amaral</div>
              <div className="arrow"><img src={icon_arrow} alt="" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Doctor;