import React from "react";
import logo_horizontal from '../../images/logo_horizontal_header.png';
import logo_quadrado from '../../images/logo_quadrado.svg';
import hamburguer from '../../images/icon_hamburguer.svg';
import back_icon from '../../images/back_icon.svg';
import ico_menu_close from '../../images/ico_menu_close.svg';
import ico_whatsapp from '../../images/ico_whatsapp_white.svg';
import ico_email from '../../images/ico_email_white.svg';

const Header = () => {
    return (
      <header>
		<div className="type_01" style={{display:"none"}}>
			<img src={logo_horizontal} className="logo_horizontal" alt=""/>
		</div>
		
		<div className="type_02">
			{/* <div className="hambuguer">
				<img src={hamburguer} className="hamburguer" alt="" />
			</div> */}
			<div className="logo">
				<img src={logo_quadrado} className="logo_quadrado" alt="" />
			</div>	
			{/* <div className="persona">
				<span>GM</span>
			</div> */}
		</div>
		
		
		<div className="type_03" style={{display:"none"}}>
			<div className="back">
				<img src={back_icon} alt="" />
			</div>
			<div className="title">
				<span>Teste de título</span>
				<div className="category nutraceutico" style={{display: "none"}}></div>
				<div className="persona"><span>JA</span></div>
			</div>
			
		</div>
		
        <div className="menu" >
			<div className="wrap">
				<div className="top">
					<div className="close"><img src={ico_menu_close} alt="" /></div>
					<img src={logo_horizontal} alt="" />
				</div>
				<div className="list">
					<a href="{null}" className="item">Corpo Técnico</a>
					<a href="{null}" className="item">Certificações</a>
					<a href="{null}" className="item">Sobre Almofariz</a>
					<div className="item">Contato</div>
					<a href="{null}" className="item sub whatsapp">
						<img src={ico_whatsapp} alt="" />
						(11) 9 3333 - 3333
					</a>
					<a href="mailto:contato@almofariz.com.br" className="item sub email">
						<img src={ico_email} alt="" />
						contato@almofariz.com.br
					</a>
					<a href="{null}" className="item sub nooline">Sair da conta</a>
					<a href="{null}" className="item sub nooline">Ajuda</a>
					
				</div>
			</div>
			<div className="backmask"></div>
		</div>
		
      </header>
    )
}

export default Header;