import React from "react";
import icon from '../../images/icon_login.svg';

const Lostpassword = () => (
	<>
		<div className="top_block">
			<div className="wrap">
				<div className="top">
					<div className="title">Bem vindo</div>
					<div className="icon"><img src={icon} alt=""/></div>
				</div>
				<div className="bottom">
					<div className="desc">Para reiniciar sua senha, entre em contato com nosso suporte técnico pelo botão abaixo.</div>
				</div>
			</div>
		</div>
		<div className="content">
			<div className="wrap">
				<button onClick={() => {window.location.href = 'mailto: tecnico@almofariz.com.br'}}  className="button">Suporte técnico por e-mail</button>			
			</div>
		</div>
	</>
);

export default Lostpassword;