import React, {useState} from "react";
import icon from '../../images/icon_login.svg';
import InputMask from 'react-input-mask';
import Api  from "../../app/api";
import Authentication  from "../../app/authentication";


const Login = (props) => {

	const [label, setLabel] = useState('Entrar');
	// const [cpf, setCpf] = useState('179.992.918-38');
	const [cpf, setCpf] = useState();
	//const [password, setPassword] = useState("")
	const [password, setPassword] = useState("Palmeira01")
	const [disabled, setDisabled] = useState(false);
	const [fCerta, setFCerta] = useState(null)
	const validarCpf = require('validar-cpf');

	const submitLogin = async () => {
		const cpfClean = clearCpf(cpf)
		if(!validarCpf(cpfClean) && password.length <= 2) {
			alert("Por favor, digite um CPF e uma Senha válidos");
			return
		}		
		
		setLabel("Aguarde...")
		setDisabled(true)

		const reponse = await Api.getUserByCpf(cpfClean)
        if(handleResponse(reponse)) {
			return
		}
		setDisabled(false)
		setLabel("Login")
    }

	const handleResponse = async (response) => {
		const cpfClean = clearCpf(cpf)
		
		if(response.RETORNO === 'ERRO'){
			return alert(response.DESCRICAO)
		}

		if(response.RETORNO === "OK"){
			const responseApi = await Api.authenticationCustomer({
				cpf: cpfClean,
				password: password
			})
			if(!responseApi.status){
				var messagem = 'Por favor, verifique os seguintes erros \n\n'
				responseApi.object.forEach((item) => {
					messagem += `${item.message}\n`
				})
				setDisabled(false)
				setLabel("Login")
				return alert(messagem)
			}
			Authentication.authenticationSuccess(responseApi.object, response)
		}
	}

	const onChangeCpf = (evt, value) => {
		setCpf(evt.target.value)
	}

	const onChangePassword = (evt, value) => {
		setPassword(evt.target.value)
	}

	const clearCpf = () => {
		return cpf.replace(/\D/gim, '');
	}

	return (
		<>
			<div className="top_block">
				<div className="wrap">
					<div className="top">
						<div className="title">Login</div>
						<div className="icon"><img src={icon} alt=""/></div>
					</div>
					<div className="bottom">
						<div className="desc">Utilize seu CPF para acessar sua conta e fazer novo registo.</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="wrap">
					<div className="input_log paciente">
						<InputMask autoFocus mask="999.999.999-99" val="" name="cpf" placeholder="CPF"  onChange={onChangeCpf} defaultValue={cpf} />
						<InputMask autoFocus val="" name="password" type="password" placeholder="Senha"  onChange={onChangePassword} />
					</div>

					<div className="button_login">
						<button onClick={submitLogin}  className="button">{label}</button>
						<center>... ou ... </center>
						<button onClick={() => {window.location.href = '/singup'}}  className="button">Primeiro acesso</button>
						<center><a href="/login/lostpassword">Esqueci minha senha sem email</a></center>
					</div>
					
				</div>
			</div>	
		</>
	)
};

export default Login;